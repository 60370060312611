const axios = require('axios')
const userService = require("@/services/user.service");
const api_base = process.env.VUE_APP_API_BASE_URL

const createVehicleAccess = async (vehicle) => {
    const url = `${api_base}/v1/vehicles`

    return await post(url, vehicle)
}

const getMyAccessLists = async () => {
    const me = await userService.me()
    const url = `${api_base}/v1/user/${me._id}/vehicles`

    const accessList = await axios.get(url)
        .catch(() => {
            console.log('ERROR GETTING ACCESS LIST')
            return {
                data: {
                    vehicles: []
                }
            }
        })

    return accessList.data.vehicles
}

const deleteAccess = async (parking_id, plate) => {
    const url = `${api_base}/v1/vehicles/${parking_id}/${plate}`

    const result = await del(url).catch((error) => {
        console.log('ERROR DELETING ACCESS')
        const retError = {
            errors: error.response.data
        }

        retError.status = error.response.status

        return retError
    })

    return result
}

// PRIVATES

const post = async (url, data) => {
    return await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })
}

const del = async (url, data) => {
    const result = await axios.delete(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })

    return result
}

module.exports = {
    createVehicleAccess,
    getMyAccessLists,
    deleteAccess
}