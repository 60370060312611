<script>
import Layout from "../../layouts/main"
import PageHeader from "@/components/page-header"
import {required} from "vuelidate/lib/validators"

import DatePicker from "vue2-datepicker"

import {createVehicleAccess, deleteAccess, getMyAccessLists} from '@/services/access.service'

import {getUserParkings} from "@/services/parkings.service";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker
  },
  async mounted() {
    this.userParkings = await getUserParkings()
    this.userAccessList = await getMyAccessLists()
    this.parkingOptions = this.getParkingOptions()
  },
  data() {
    return {
      title: "Lista de Acceso",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Listas",
          active: true
        }
      ],
      weekDays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi',  'Sa', 'Do'],
      userAccessList: [],
      userParkings: [],
      parkingOptions: [],
      showmodal: false,
      showmodal_edit: false,
      submitted: false,
      isCreateError: false,
      createError: "",
      editError: "",
      isEditError: false,
      vehicleData: [],
      newVehicle: {
        plate: '',
        name: '',
        lot: '',
        id: '',
        parking_id: '',
        range: '',
        days: []
      }
    }
  },
  validations: {
    newVehicle: {
      parking_id: { required },
      plate: { required },
      name: { required },
      id: { required }
    }
  },
  methods: {
    async create() {
      this.submitted = true
      this.isCreateError = false
      this.createError = ""
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        const result = await createVehicleAccess(this.newVehicle)
        if (result.status >= 400) {
          if (result.status === 400) {
            const errors = Object.values(result.errors)
            errors.forEach(error => this.createError += (this.createError === null) ? '': `${error[0]} <br/>` )
          } else {
            this.createError = result.errors.status
          }
          this.isCreateError = true
        } else {
          this.userAccessList = await getMyAccessLists()
          this.showmodal = false
        }
      }
    },
    async edit() {
      this.submitted = true
      this.isEditError = false
      this.editError = ""
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        const result = await createVehicleAccess(this.newVehicle)
        if (result.status >= 400) {
          if (result.status === 400) {
            const errors = Object.values(result.errors)
            errors.forEach(error => this.editError += (this.editError === null) ? '': `${error[0]} <br/>` )
          } else {
            this.editError = result.errors.status
          }
          this.isEditError = true
        } else {
          this.userAccessList = await getMyAccessLists()
          this.showmodal_edit = false
        }
      }
    },
    async deleteAccess(parking_id, plate) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "Borrar el acceso no es reversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.value) {
          await deleteAccess(parking_id, plate)
          this.userAccessList = await getMyAccessLists()
          Swal.fire("Borrado!", "El acceso ha sido eliminado.", "success");
        }
      });
    },
    loadEdit(access_id) {
      const access = this.userAccessList.filter(a => a._id === access_id)[0]
      if (access) {
        console.log(access)
        this.newVehicle.id = access.id
        this.newVehicle.lot = access.lot
        this.newVehicle.plate = access.plate
        this.newVehicle.name = access.name
        this.newVehicle.parking_id = access.parking_id
        this.newVehicle.range = access.range
        this.newVehicle.days = access.days
      }

      this.showmodal_edit = true
    },
    hideModal() {
      this.submitted = false
      this.showmodal = false
      this.newVehicle = {
        plate: '',
        name: '',
        lot: '',
        id: '',
        parking_id: '',
        range: '',
        days: []
      }
    },
    hideEditModal() {
      this.submitted = false
      this.showmodal_edit = false
      this.newVehicle = {
        plate: '',
        name: '',
        lot: '',
        id: '',
        parking_id: '',
        range: '',
        days: []
      }
    },
    getParkingName(id) {
      const parking = this.userParkings.filter(parking => parking._id == id)
      return parking[0].name
    },
    getParkingOptions() {
      return this.userParkings.map((parking) => {
        return {
          value: parking._id,
          text: parking.name
        }
      })
    },
    formatNumber(num) {
      if(num < 10) {
        return `0${num}`
      }
      return num
    },
    formatDate(input) {
      const d = new Date(input)

      return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()} `
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div>
                <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                  <i class="mdi mdi-plus mr-2"></i> Agregar Vehiculo
                </a>
              </div>
              <div class="table-responsive mt-3">
                <table
                    class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                  <tr>
                    <th>Placa</th>
                    <th>Nombre</th>
                    <th>Identificación</th>
                    <th>Parqueo Asignado</th>
                    <th style="width: 120px;">Acción</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in userAccessList" :key="index">
                    <td>{{item.plate}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.id}}</td>
                    <td>{{getParkingName(item.parking_id)}}</td>
                    <td>
                      <a
                          @click="loadEdit(item._id)"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar Información"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                          @click="deleteAccess(item.parking_id, item.plate)"
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Borrar"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CREATE Modal -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="Agregar un vehiculo"
        title-class="text-dark font-18"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
      <b-alert
          v-model="isCreateError"
          class="mt-3"
          variant="danger"
          dismissible>
        <span v-html="createError"></span>
      </b-alert>
      <form @submit.prevent="create">
        <div class="form-group">
          <label for="parking_id">Parqueo Asignado</label>
          <b-form-select
              v-model="newVehicle.parking_id"
              :options="parkingOptions"
              :class="{ 'is-invalid': submitted && $v.newVehicle.parking_id.$error }"></b-form-select>
          <div
              v-if="submitted && !$v.newVehicle.parking_id.required"
              class="invalid-feedback"
          >El parqueo asignado es invalido</div>
        </div>
        <div class="form-group">
          <label for="plate"># Placa</label>
          <input
              id="plate"
              v-model="newVehicle.plate"
              type="text"
              class="form-control"
              placeholder="Ingrese numero de placa"
              :class="{ 'is-invalid': submitted && $v.newVehicle.plate.$error }"
          />
          <div
              v-if="submitted && !$v.newVehicle.plate.required"
              class="invalid-feedback"
          >Ingrese placa del vehiculo</div>
        </div>
        <div class="form-group">
          <label for="name">Propietario</label>
          <input
              id="direccion"
              v-model="newVehicle.name"
              type="name"
              name="name"
              class="form-control"
              placeholder="Nombre del propietario"
              :class="{ 'is-invalid': submitted && $v.newVehicle.name.$error }"
          />
          <div v-if="submitted && $v.newVehicle.name.$error" class="invalid-feedback">
            <span v-if="!$v.newVehicle.name.required">Se requiere el nombre del propietario</span>
          </div>
        </div>
        <div class="form-group">
          <label for="position">Identificación</label>
          <input
              id="open"
              v-model="newVehicle.id"
              type="text"
              class="form-control"
              placeholder="Ingrese la identificación del propietario"
              :class="{ 'is-invalid': submitted && $v.newVehicle.id.$error }"
          />
          <div
              v-if="submitted && !$v.newVehicle.id.required"
              class="invalid-feedback"
          >La identificación del propietario es requerida</div>
        </div>
        <div class="form-group">
          <label for="position">Parqueo asignado</label>
          <input
              id="close"
              v-model="newVehicle.lot"
              type="text"
              class="form-control"
              placeholder="Ingrese el parqueo asignado"
          />
        </div>
        <div class="form-group">
          <label for="position">Tiempo de estadia <i>(opcional)</i></label>
          <date-picker v-model="newVehicle.range" range append-to-body lang="en" confirm></date-picker>
        </div>
        <div class="form-group">
          <label for="position">Dias de la semana <i>(opcional)</i></label>
          <b-form-checkbox-group
              id="daysInWeek"
              v-model="newVehicle.days"
              :options="weekDays"
              name="dias"
              class="ml-4"
              aria-label="Dias De la Semana"
          ></b-form-checkbox-group>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Guardar</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end create modal -->

    <!-- EDIT Modal -->
    <b-modal
        id="modal-1"
        v-model="showmodal_edit"
        title="Editar un vehiculo"
        title-class="text-dark font-18"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
      <b-alert
          v-model="isEditError"
          class="mt-3"
          variant="danger"
          dismissible>
        <span v-html="editError"></span>
      </b-alert>
      <form @submit.prevent="edit">
        <div class="form-group">
          <label for="parking_id">Parqueo Asignado</label>
          <b-form-select
              v-model="newVehicle.parking_id"
              :options="parkingOptions"
              :class="{ 'is-invalid': submitted && $v.newVehicle.parking_id.$error }"></b-form-select>
          <div
              v-if="submitted && !$v.newVehicle.parking_id.required"
              class="invalid-feedback"
          >El parqueo asignado es invalido</div>
        </div>
        <div class="form-group">
          <label for="plate"># Placa</label>
          <input
              id="plate"
              v-model="newVehicle.plate"
              type="text"
              class="form-control"
              placeholder="Ingrese numero de placa"
              :class="{ 'is-invalid': submitted && $v.newVehicle.plate.$error }"
              disabled
          />
          <div
              v-if="submitted && !$v.newVehicle.plate.required"
              class="invalid-feedback"
          >Ingrese placa del vehiculo</div>
        </div>
        <div class="form-group">
          <label for="name">Propietario</label>
          <input
              id="direccion"
              v-model="newVehicle.name"
              type="name"
              name="name"
              class="form-control"
              placeholder="Nombre del propietario"
              :class="{ 'is-invalid': submitted && $v.newVehicle.name.$error }"
          />
          <div v-if="submitted && $v.newVehicle.name.$error" class="invalid-feedback">
            <span v-if="!$v.newVehicle.name.required">Se requiere el nombre del propietario</span>
          </div>
        </div>
        <div class="form-group">
          <label for="position">Identificación</label>
          <input
              id="open"
              v-model="newVehicle.id"
              type="text"
              class="form-control"
              placeholder="Ingrese la identificación del propietario"
              :class="{ 'is-invalid': submitted && $v.newVehicle.id.$error }"
          />
          <div
              v-if="submitted && !$v.newVehicle.id.required"
              class="invalid-feedback"
          >La identificación del propietario es requerida</div>
        </div>
        <div class="form-group">
          <label for="position">Parqueo asignado</label>
          <input
              id="close"
              v-model="newVehicle.lot"
              type="text"
              class="form-control"
              placeholder="Ingrese el parqueo asignado"
          />
        </div>
        <div class="form-group">
          <label for="position">Tiempo de estadía <i>(opcional)</i></label><br/>
          <span>Actual: {{formatDate( newVehicle.range[0] )}} - {{ formatDate( newVehicle.range[1] )}}</span>
          <date-picker v-model="newVehicle.range" range append-to-body  lang="en" confirm></date-picker>
        </div>
        <div class="form-group">
          <label for="position">Dias de la semana <i>(opcional)</i></label>
          <b-form-checkbox-group
              id="daysInWeek"
              v-model="newVehicle.days"
              :options="weekDays"
              name="dias"
              class="ml-4"
              aria-label="Dias De la Semana"
          ></b-form-checkbox-group>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">Guardar</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">Cancelar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end edit modal -->

  </Layout>
</template>